import React, { useState, useRef } from "react";

const usePigeon = () => {
  const testMode = false; // SET TO FALSE IF NOT TESTING
  const [allNetworks, setAllNetworks] = useState([]);
  const [isConnectedToPC, setIsConnectedToPC] = useState(false);
  const [connectingToNetwork, setConnectingToNetwork] = useState(false); //when user is actually in the process of conecting to a network (by clicking the button on UI)
  const [step1Complete, setStep1Complete] = useState(false);
  const [step2Complete, setStep2Complete] = useState(false);
  const [step3Complete, setStep3Complete] = useState(false);
  const device = useRef();

  let endpointNumber = 1;
  var headerBuffer = new ArrayBuffer(2);
  var Uint8ArrayHeader = new Uint8Array(headerBuffer);
  Uint8ArrayHeader[0] = 0x55;
  Uint8ArrayHeader[1] = 0xaa;
  Uint8ArrayHeader[2] = 0x15;
  Uint8ArrayHeader[3] = 0x00;

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const intToByteArray = function (val, numBytes) {
    // we want to represent the input as a 8-bytes array
    var byteArray = new Array(numBytes).fill(0);

    for (var index = 0; index < byteArray.length; index++) {
      var byte = val & 0xff;
      byteArray[index] = byte;
      val = (val - byte) / 256;
    }

    return byteArray;
  };

  const connectToPigeon = async () => {
    console.log("in connectToPigeon.");
    // let deviceTemp = device;
    console.log(typeof device.current);
    if (device.current == null) {
      try {
        device.current = await navigator.usb.requestDevice({
          filters: [{ vendorId: 1317 }],
        });
      } catch (error) {
        // alert("Error: " + error.message);
      }
    }
    if (!device.current.opened) {
      try {
        await device.current.open();
        await device.current.selectConfiguration(1);
        await device.current.claimInterface(0);
      } catch (error) {
        // alert("Error: " + error.message);
      }
    }
    // setDevice(deviceTemp);
    // device = deviceTemp;
    if (device.current.opened) {
      setIsConnectedToPC(true);
    } else {
      setIsConnectedToPC(false);
    }
  };

  navigator.usb.ondisconnect = (event) => {
    console.log("In navigator.usb.ondisconnect");
    // Add event.device to the UI.

    // alert("usb disconnected");
    console.log("usb disconnected");
    setIsConnectedToPC(false);

    if (connectingToNetwork) {
      setStep1Complete(true);
    }
  };

  navigator.usb.onconnect = async (event) => {
    console.log("In navigator.usb.onconnect");
    // Add event.device to the UI.

    setIsConnectedToPC(true);
    // alert("usb connected");
    console.log("usb connected");
    console.log(event);
    device.current = event.device;

    if (connectingToNetwork) {
      let step2CompleteTemp = false;
      if (step1Complete == true) {
        step2CompleteTemp = true;
        setStep2Complete(true);
      }

      let pigeonOnNetwork;
      let sleepTime;
      if (testMode) {
        sleepTime = 5;
      } else {
        sleepTime = 60;
      }

      await connectToPigeon();
      for (let i = 0; i < sleepTime; i++) {
        pigeonOnNetwork = await isPigeonOnNetwork();
        if (pigeonOnNetwork) {
          break;
        }
        await sleep(2000);
      }
      console.log("is pigeon on network:");
      console.log(pigeonOnNetwork);
      if (pigeonOnNetwork && step2CompleteTemp) {
        setStep3Complete(true);
      } else {
        setStep3Complete(null); // null means the connection tried and failed
      }

      setConnectingToNetwork(false); // finished the steps so set back to false
    }
  };

  const sendCommand = async (command) => {
    console.log("In sendCommand.");
    let response;
    try {
      var encoder = new TextEncoder();
      var data = encoder.encode(command);

      // find the sum
      let byteSum = 0;
      for (const byte of data) {
        byteSum += byte;
      }

      console.log("byte array:");
      console.log(intToByteArray(byteSum));

      const dataSize = intToByteArray(data.length, 2);

      const crcByteArray = intToByteArray(byteSum, 4);

      var totalCommand = new Uint8Array([
        ...Uint8ArrayHeader,
        ...dataSize,
        ...crcByteArray,
        ...data,
      ]);

      let byteString = "";
      for (const byte of totalCommand) {
        byteString += " " + byte.toString(16);
      }

      const transferOutResult = await device.current.transferOut(
        2,
        totalCommand
      );
      console.log(transferOutResult);
      const transferInResult = await device.current.transferIn(1, 512);
      console.log(transferInResult);
      console.log("data:");
      console.log(transferInResult.data);
      const decoder = new TextDecoder();

      response = decoder.decode(transferInResult.data);
      response = response.substr(response.indexOf("{"), response.length - 1);
      console.log("Received: " + typeof response);
      response = JSON.parse(response);
      //   for (let i = 0; i < allNetworks["ap"].length; i++) {
      //     const oneNetwork = allNetworks["ap"][i]["ssid"];
      //   }
      console.log("response right after parse: " + typeof response);
    } catch (error) {
      // alert("ERROR: " + error.message);
    }

    return response;
  };

  const rebootPigeon = async () => {
    console.log("In rebootPigeon.");
    const response = await sendCommand('{"cmd":"reboot"}');
  };

  const getWifiNetworks = async () => {
    console.log("In getWifiNetworks.");
    await connectToPigeon();
    const response = await sendCommand('{"cmd":"get_wlan_ap"}');
    let allNetworksTemp = response;
    allNetworksTemp = allNetworksTemp.ap;
    allNetworksTemp.map((item, index) => console.log(item));

    console.log("is pigeon on network:");
    console.log(await isPigeonOnNetwork());
    setAllNetworks(allNetworksTemp);
  };

  const connectToNetwork = async (networkName, password) => {
    console.log("In connectToNetwork.");
    setConnectingToNetwork(true);
    resetAllSteps();
    // await connectToPigeon();
    // var password = document.getElementById("passwordTextArea").value;
    // const command =
    //   '{"cmd":"set_wlan_config","utf8":"0","ssid":"NETGEAR46","password":"' +
    //   password +
    //   '","dhcp":"1","ip":"192.168.1.128","mask":"255.255.255.0","gateway":"192.168.1.1","dns":"192.168.1.1"}';
    const command =
      '{"cmd":"set_wlan_config","utf8":"0","ssid":"' +
      networkName +
      '","password":"' +
      password +
      '","dhcp":"1","ip":"","mask":"","gateway":"","dns":""}';
    console.log(command);
    const response = await sendCommand(command);
    await rebootPigeon();
  };

  const isPigeonOnNetwork = async () => {
    // checks if the Pigeon is connected to a network
    console.log("In isPigeonOnNetwork.");
    const command = '{"cmd":"get_network_info"}';
    const response = await sendCommand(command);
    if (response.wlan.ip.replace(/[^.]/g, "").length == 3) {
      return true;
    } else {
      return false;
    }
  };

  const resetAllSteps = () => {
    setStep1Complete(false);
    setStep2Complete(false);
    setStep3Complete(false);
  };

  return [
    allNetworks,
    getWifiNetworks,
    connectToNetwork,
    isConnectedToPC,
    step1Complete,
    step2Complete,
    step3Complete,
    connectingToNetwork,
    resetAllSteps,
  ];
};

export default usePigeon;
