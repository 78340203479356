import CircleIcon from "@mui/icons-material/Circle";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const NetworkConnectionStatusPopup = (props) => {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleToggle = () => {
    props.setOpen(!props.open);
  };

  function generate(element) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  }

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const oneStep = (text, condition) => {
    let icon;

    if (condition) {
      icon = <CheckCircleIcon />;
    } else if (condition == false) {
      icon = <CircularProgress />;
    } else {
      icon = <CancelIcon />;
    }

    return (
      <ListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={text}
          secondary={secondary ? "Secondary text" : null}
        />
      </ListItem>
    );
  };

  const configuringDeviceIcon = () => {};
  const rebootingDeviceIcon = () => {};
  const connectingToNetworkIcon = () => {};

  console.log("is rebooting:");
  console.log(props.isRebooting);

  return (
    <div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
        onClick={handleClose}
      >
        <Grid item xs={"auto"} md={"auto"}>
          <Demo>
            <List dense={dense}>
              {oneStep("Configuring device", props.step1Complete)}
              {oneStep("Rebooting", props.step2Complete)}
              {oneStep("Connecting to network", props.step3Complete)}
            </List>
          </Demo>
        </Grid>
      </Backdrop>
    </div>
  );
};

export default NetworkConnectionStatusPopup;
