import React, { useState } from "react";
import usePigeon from "../../hooks/use-pigeon";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import SignalCellularAlt2BarOutlinedIcon from "@mui/icons-material/SignalCellularAlt2BarOutlined";
import SignalCellularAlt1BarOutlinedIcon from "@mui/icons-material/SignalCellularAlt1BarOutlined";

const WifiList = (props) => {
  const handleListItemClick = (event, index) => {
    props.setWifiListIndex(index);
  };

  const getSignalIcon = (signal) => {
    if (signal > 35) {
      return <SignalCellularAltOutlinedIcon />;
    } else if (signal > 20) {
      return <SignalCellularAlt2BarOutlinedIcon />;
    } else {
      return <SignalCellularAlt1BarOutlinedIcon />;
    }
  };

  const getNetworkList = (networks) => {
    return networks.map((item, index) => (
      <ListItemButton
        selected={props.wifiListIndex === index}
        onClick={(event) => handleListItemClick(event, index)}
      >
        <ListItemIcon>{getSignalIcon(item.signal)}</ListItemIcon>
        <ListItemText primary={item.ssid} />
      </ListItemButton>
    ));
  };

  console.log("all networks:" + props.allNetworks);

  return (
    <>
      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <List component="nav" aria-label="main mailbox folders">
          {getNetworkList(props.allNetworks)}
        </List>
      </Box>
    </>
  );
};

export default WifiList;
