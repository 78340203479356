import React, { useState } from "react";
import usePigeon from "./hooks/use-pigeon";
import WifiList from "./components/WifiList/WifiList";
import PasswordTextField from "./components/PasswordTextField/PasswordTextField";
import Button from "@mui/material/Button";
import NetworkConnectionStatusPopup from "./components/NetworkConnectionStatusPopup/NetworkConnectionStatusPopup";
import AlertDialog from "./components/AlertDialog/AlertDialog";
import { Grid } from "@mui/material";
import ResponsiveAppBar from "./components/ResponsiveAppBar/ResponsiveAppBar";

const App = () => {
  const [
    allNetworks,
    getWifiNetworks,
    connectToNetwork,
    isConnectedToPC,
    step1Complete,
    step2Complete,
    step3Complete,
    connectingToNetwork,
    resetAllSteps,
  ] = usePigeon();
  const [password, setPassword] = useState("");
  const [wifiListIndex, setWifiListIndex] = useState();
  const [
    showNetworkConnectionStatusPopup,
    setShowNetworkConnectionStatusPopup,
  ] = useState(false);
  const [showStep3ErrorPopup, setShowStep3ErrorPopup] = useState(true);

  console.log("allNetworks");
  console.log(allNetworks);

  const showPopup = showNetworkConnectionStatusPopup && step3Complete != null;

  if (showNetworkConnectionStatusPopup && connectingToNetwork == false) {
    setShowNetworkConnectionStatusPopup(false);
  }

  return (
    <>
      <ResponsiveAppBar />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <>
          <Button
            onClick={() => {
              getWifiNetworks(); // pop up to select Pigeon and then get wifi networks
            }}
            variant="contained"
          >
            Connect
          </Button>
          <WifiList
            allNetworks={allNetworks}
            wifiListIndex={wifiListIndex}
            setWifiListIndex={setWifiListIndex}
          />
          {wifiListIndex != null ? (
            <>
              <PasswordTextField
                password={password}
                setPassword={setPassword}
              />
              <Button
                disabled={password == ""}
                onClick={() => {
                  setShowNetworkConnectionStatusPopup(true);
                  connectToNetwork(allNetworks[wifiListIndex].ssid, password);
                }}
                variant="contained"
              >
                Connect to Wifi
              </Button>
            </>
          ) : null}
        </>
        <NetworkConnectionStatusPopup
          open={showNetworkConnectionStatusPopup}
          setOpen={setShowNetworkConnectionStatusPopup}
          isConnectedToPC={isConnectedToPC}
          step1Complete={step1Complete}
          step2Complete={step2Complete}
          step3Complete={step3Complete}
        />
        {console.log("step3Complete:")}
        {console.log(step3Complete)}
        {step3Complete == null ? (
          <AlertDialog
            headerText={"ERROR"}
            bodyText={
              "Could not connect to wifi network. Please ensure your password is correct and try again."
            }
            resetAllSteps={resetAllSteps}
          />
        ) : step3Complete == true ? (
          <AlertDialog
            headerText={"SUCCESS"}
            bodyText={"Connection Established. You can now print wirelessly."}
            resetAllSteps={resetAllSteps}
          />
        ) : null}
      </Grid>
    </>
  );
};

export default App;
